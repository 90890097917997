<template>
  <q-card
    :bordered="!dark.isActive"
    class="fit"
    flat
    square
  >
    <div class="column fit justify-between">
      <div class="col">
        <nuxt-link
          :to="path"
          :title="`${product.name} for sale at KYGUNCO.`"
          @click="select()"
        >
          <q-img
            :src="thumbnailUrl"
            :alt="`${product.name} for sale at KYGUNCO.`"
            error-src="/placeholder.svg"
            height="200px"
            fit="contain"
            class="bg-white"
            spinner-color="primary"
          >
            <template #error>
              <div class="absolute-full flex flex-center bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="150"
                >
                  <!-- eslint-disable @stylistic/max-len -->
                  <path
                    fill="#ccc"
                    fill-rule="evenodd"
                    d="M96.492 49.979c34.39 0 62.17 10.64 62.17 23.78s-27.78 23.78-62.17 23.78c-23.56 0-44.07-5-54.61-12.41 10.59 5.4 28.5 9 48.82 9 32.53 0 58.91-9.1 58.91-20.32s-26.38-20.37-58.91-20.37c-20.32 0-38.23 3.55-48.82 8.94 10.54-7.39 31.05-12.4 54.61-12.4m40.1 23.78c0 7.18-12.71 13.59-27.77 13.48h-.25l-.45-.81a28.93 28.93 0 0 1-3.07-12.67 28.48 28.48 0 0 1 3.07-12.66l.46-.82h.24c15.06-.12 27.77 6.29 27.77 13.48zm-34.84 0a32.17 32.17 0 0 1 3.07-13.48h-28.43l-.46.82a28.55 28.55 0 0 0-3.05 12.66 28.93 28.93 0 0 0 3.07 12.67l.44.81h28.49a32.24 32.24 0 0 1-3.13-13.48zm-32.18 0a32 32 0 0 1 3.07-13.48h-5.17l-.47.82a28.71 28.71 0 0 0-3.06 12.66 28.93 28.93 0 0 0 3.07 12.67l.45.81h5.19a32.24 32.24 0 0 1-3.08-13.48z"
                  />
                  <!-- eslint-enable @stylistic/max-len -->
                </svg>
              </div>
            </template>

            <section
              v-if="sale.active"
              class="row absolute-top-left justify-evenly items-center"
            >
              <q-chip
                :label="sale.discount > 15 ? 'Great Deal!' : 'On Sale'"
                color="positive"
                class="text-weight-medium no-border-radius"
                style="box-sizing: border-box;"
                square
              />
            </section>
            <section class="column absolute-top-right q-px-xs">
              <q-avatar
                v-if="product.isQualifiedProfessional"
                :icon="mdiPoliceBadgeOutline"
                text-color="white"
                color="blue-10"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.isClass3"
                :icon="mdiRomanNumeral3"
                text-color="white"
                color="warning"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.isPartnerFulfilled"
                :icon="mdiWarehouse"
                text-color="white"
                color="primary"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.orderType == 'Preorder'"
                :icon="mdiCalendarStar"
                text-color="white"
                color="primary"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.orderType == 'Backorder'"
                :icon="mdiTruckFastOutline"
                text-color="white"
                color="primary"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.hasRebates"
                :icon="mdiCalendarSyncOutline"
                text-color="white"
                color="positive"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
            </section>
            <div
              v-if="product.averageScore"
              class="row absolute-bottom justify-evenly items-center"
            >
              <div class="row items-center">
                <span class="text-body2 q-mr-sm">
                  {{ rating(product.averageScore) }}
                </span>
                <q-rating
                  :model-value="product.averageScore"
                  :icon-half="mdiStarHalfFull"
                  :icon="!product.averageScore ? mdiStarOutline : undefined"
                  max="5"
                  size="1em"
                  color="secondary"
                  class="q-pa-xs"
                  readonly
                />
              </div>
            </div>
          </q-img>
        </nuxt-link>

        <q-card-section class="q-pb-none">
          <div class="row">
            <div class="col text-caption text-grey ellipsis">
              {{
                product.style.startsWith('SIG-') ? product.mpn : product.style
              }}
            </div>
            <div class="col-3 text-caption text-grey text-right">
              #{{ product.id }}
            </div>
          </div>

          <nuxt-link
            :to="`/product/${product.slug}`"
            :title="product.name"
            style="text-decoration: none; color: inherit"
            @click="select()"
          >
            <div class="text-subtitle2 title ellipsis-2-lines">
              {{ product.name }}
            </div>
          </nuxt-link>
        </q-card-section>
      </div>

      <div class="col-auto">
        <q-card-section class="row items-center justify-around">
          <ProductCardPrice
            :product="product"
            class="q-pa-xs"
          />
          <ProductCardAction :product="product" />
        </q-card-section>

        <div v-if="!product.isClass3 && $credova.valid(product.price)">
          <q-separator />

          <ProductCardFinancing :product="product" />
        </div>
      </div>
    </div>
  </q-card>
</template>

<script lang="ts" setup>
import {
  mdiStarHalfFull,
  mdiStarOutline,
  mdiPoliceBadgeOutline,
  mdiRomanNumeral3,
  mdiCalendarStar,
  mdiTruckFastOutline,
  mdiWarehouse,
  mdiCalendarSyncOutline,
} from '@quasar/extras/mdi-v7';
import type { Product } from '~/types/search';

const props = defineProps({
  product: {
    type: Object as () => Product,
    required: true,
  },
});

const { $credova } = useNuxtApp();
const { dark } = useQuasar();
const { rating } = useFormatting();
const { path, sale, select } = useCatalog(props.product);

const thumbnailUrl = computed(() => {
  return props.product.thumbnailUrl ?? '/placeholder.svg';
});
</script>

<style lang="scss" scoped>
.q-img__content > div {
  backdrop-filter: blur(10px);
  padding: 4px;
}

.title {
  font-size: 0.9rem;
}
</style>
